"use client";

import { ChangeEvent, useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { Observer } from "gsap/Observer";
import { Project } from "../types/project.types";
import ScrollDown from "./ui/scroll-down";
import ScrollUp from "./ui/scroll-up";
import useObserveFooter from "../hooks/useObserveFooter";
import useScrollTo from "../hooks/useScroll";
import useAutoScroll from "../hooks/useAutoScroll";
import Logo from "./ui/logo-svg";
import { shuffleArray } from "../utils";
// import LazyImage from "./lazy-image";
import { useSearchParams } from "next/navigation";
import Image from "next/image";
import { Blurhash } from "react-blurhash";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, Observer);

const ScrollingGallery = ({ projects: _projects }: { projects: Project[] }) => {
  const outilsPic = _projects.find(
    (project) =>
      project.attributes.cover.data.attributes.name.includes("OUTILS")!
  );
  const [projects, setProjects] = useState<Project[]>();
  const [isFooterVisible, setIsFooterVisible] = useState(false);
  const [scrollSpeed, setScrollSpeed] = useState(6);
  const [drawerFooter, setDrawerFooter] = useState(false);
  const [randomize, setRandomize] = useState(false);
  const [drawerFooterVisible, setDrawerFooterVisible] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);
  const autoScrollTweenRef = useRef<gsap.core.Tween | null>(null);
  const restartTimerRef = useRef<gsap.core.Tween | null>(null);
  const [mobileScrollTopAtInit, setMobileScrollTopAtInit] = useState(false);
  const searchParams = useSearchParams();

  useEffect(() => {
    setProjects([
      ...shuffleArray(
        _projects.filter((project) => project.id !== outilsPic!.id)
      ),
      outilsPic!,
    ]);
  }, [randomize]);

  const { startAutoScroll, pauseScroll, resumeScroll, isPaused } =
    useAutoScroll({
      scrollContainerRef,
      autoScrollTweenRef,
      restartTimerRef,
      projectsLength: projects?.length || 0,
      scrollSpeed,
    });

  const { scrollFooter, scrollTop } = useScrollTo({
    scrollContainerRef,
    footerRef,
    autoScrollTweenRef,
    restartTimerRef,
    startAutoScroll,
    totalImages: _projects.length,
  });

  const scrollToFooter = () => {
    if (!drawerFooter) {
      scrollFooter();
    } else {
      setDrawerFooterVisible((prev) => !prev);
    }
  };

  const scrollToTop = () => {
    scrollTop();
    setRandomize((prev) => !prev);
  };

  const handleClickBtn = () => {
    if (isFooterVisible) {
      scrollToTop();
    } else {
      scrollToFooter();
    }
  };

  useObserveFooter(scrollContainerRef, footerRef, setIsFooterVisible);

  const minSpeed = 0.5;
  const maxSpeed = 20;

  const increaseSpeed = () => {
    setScrollSpeed((prevSpeed) => Math.max(prevSpeed - 0.5, minSpeed));
  };

  const decreaseSpeed = () => {
    setScrollSpeed((prevSpeed) => Math.min(prevSpeed + 0.5, maxSpeed));
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDrawerFooter(event.target.checked);
  };

  const handleImageClick = () => {
    if (isPaused) {
      resumeScroll();
    } else {
      pauseScroll();
    }
  };

  useEffect(() => {
    if (!mobileScrollTopAtInit && projects) {
      scrollToTop();
      setMobileScrollTopAtInit(true);
    }
  }, [projects]);

  return (
    <>
      <div className="go-up-down">
        <button className="show-footer-btn" onClick={handleClickBtn}>
          {!drawerFooter && (isFooterVisible ? <ScrollUp /> : <ScrollDown />)}
          {drawerFooter && (drawerFooterVisible ? "X" : <ScrollDown />)}
        </button>
      </div>
      <div className="scroll-container" ref={scrollContainerRef}>
        <section className="all-projects">
          <button className="is-sticky" onClick={scrollToFooter}>
            <Logo />
          </button>
          <div className="is-sticky bottom">Site en construction</div>

          {projects?.map((project, index) => {
            if (!project) {
              return null;
            }

            return (
              <div
                key={`${project.id}-${index}`}
                className="project-section"
                onClick={handleImageClick}
              >
                <div
                  style={{
                    position: "relative",
                    aspectRatio: `${project.attributes.cover.data.attributes.width} / ${project.attributes.cover.data.attributes.height}`,
                  }}
                >
                  <Blurhash
                    hash={project.attributes.coverBlurHash}
                    width="100%"
                    height="100%"
                    resolutionX={32}
                    resolutionY={32}
                    punch={1}
                  />
                  <Image
                    src={project.attributes.cover.data.attributes.url}
                    alt={
                      project.attributes.cover.data.attributes
                        .alternativeText || ""
                    }
                    fill
                    style={{
                      opacity: 0,
                      transition: "opacity 0.3s ease-in-out",
                    }}
                    onLoad={(e) => {
                      const img = e.target as HTMLImageElement;
                      img.style.opacity = "1";
                    }}
                  />
                </div>
              </div>
            );
          })}
        </section>
        {!drawerFooter && (
          <section className="footer" ref={footerRef}>
            <div className="footer-content">
              <AgencyInfoContent />
            </div>
          </section>
        )}
        {drawerFooter && (
          <section
            className={`footer drawer ${drawerFooterVisible ? "visible" : ""}`}
          >
            <div className="footer-content">
              <AgencyInfoContent />
            </div>
          </section>
        )}
      </div>

      {searchParams.get("debug") === "true" && (
        <div className="edit">
          <div className="edit-mode">
            <div>
              <label>{scrollSpeed}s / image</label>
              <div className="speed-controls">
                <button
                  onClick={decreaseSpeed}
                  disabled={scrollSpeed >= maxSpeed}
                >
                  -
                </button>
                <button
                  onClick={increaseSpeed}
                  disabled={scrollSpeed <= minSpeed}
                >
                  +
                </button>
              </div>
            </div>
            <div className="drawer-footer-control">
              <input
                type="checkbox"
                checked={drawerFooter}
                onChange={handleChange}
                id="drawer-footer"
              />
              <label htmlFor="drawer-footer">
                {drawerFooter ? "Désactiver" : "Activer"} le footer tiroir
              </label>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ScrollingGallery;

function AgencyInfoContent() {
  return (
    <>
      <div className="agency-info">
        {/* <h3 className="footer-title">Nouveau titre</h3>
        <p>Bulle, Poirier & Justman</p> */}
        <h3 className="footer-title">Nouveau chapitre</h3>
        <div>
          <p>
            Les chapitres d’une histoire s’enchaînent, s’enrichissant au fil des
            pages de nouveaux acteurs et de nouvelles scènes, de nouvelles
            pensées…
          </p>
          <p>
            Le chapitre «&nbsp;Fabienne Bulle&nbsp;», inauguré dès 1982 par une
            jeune diplômée et déjà enseignante à l’École Spéciale d’Architecture
            se lit alors avec l’insolence de la jeunesse qui ouvre le champ des
            possibles, nourrit les envies les plus pittoresques, absorbe les
            contraintes du bien construire … les traduit par l’expression du
            plaisir du faire.
          </p>
          <p>
            Le chapitre «&nbsp;Fabienne Bulle architecte & associés&nbsp;», qui
            s’est nourri de la force de frappe des deux associés – Pascale
            Poirier et Hicham Hamze –, et dont la temporalité frôle quelques
            trois décennies, se lit comme une construction de la pérennité.
            C’est avec une persévérance sans faille, qu’il s’est attaché à
            confirmer la rare liberté de penser et de faire – sans complaisance
            d’aucune sorte, et surtout sans jamais céder à un quelconque effet
            de mode –, de ses protagonistes. Et c’est avec une énergie sans
            cesse renouvelée qu’il s’est attaché à consacrer leur figure de
            pionniers de la construction bois, à soutenir leur appétence pour le
            chantier, et à légitimer leur compréhension des savoir-faire
            techniques.{" "}
          </p>

          <p>
            Le chapitre «&nbsp;Fabienne Bulle, Pascale Poirier & Ary
            Justman&nbsp;» enfin, doit se lire comme une nouvelle aventure –
            riche de promesses. Des promesses respectueuses des acquis, certes
            des deux précédents chapitres mais aussi de la courte mais
            trépidante Nouvelle rédigée en cinq ans, par Ary Justman. Des
            promesses de dialogues intergénérationnels. Des promesses
            d’ouvertures à un monde en mouvement et questionnement, à des
            réalités sociétales, contextuelles et responsables. Son écriture
            s’esquisse, dynamique et engagée.
          </p>
        </div>
      </div>
      <div className="contact">
        <div>bpj@bullepoirierjustman.archi</div>
        <div>
          113 avenue de la République
          <br />
          92120 Montrouge
        </div>
        <div>01 46 12 02 95</div>
      </div>
    </>
  );
}
