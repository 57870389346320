import { useCallback, useEffect, RefObject, MutableRefObject, useState } from "react";
import gsap from "gsap";
import { Observer } from "gsap/Observer";

gsap.registerPlugin(Observer);

interface UseAutoScrollProps {
  scrollContainerRef: RefObject<HTMLElement>;
  autoScrollTweenRef: MutableRefObject<gsap.core.Tween | null>;
  restartTimerRef: MutableRefObject<gsap.core.Tween | null>;
  projectsLength: number;
  scrollSpeed?: number;
}

const useAutoScroll = ({
  scrollContainerRef,
  autoScrollTweenRef,
  restartTimerRef,
  projectsLength,
  scrollSpeed = 6,
}: UseAutoScrollProps) => {
  const [isPaused, setIsPaused] = useState(false);

  const startAutoScroll = useCallback(() => {
    if (!scrollContainerRef.current || isPaused) return;

    const maxScroll =
      scrollContainerRef.current.scrollHeight -
      scrollContainerRef.current.clientHeight;
    const currentScroll = scrollContainerRef.current.scrollTop;
    const remainingScroll = maxScroll - currentScroll;
    const remainingDuration =
      (remainingScroll / maxScroll) * (projectsLength * scrollSpeed);

    autoScrollTweenRef.current = gsap.to(scrollContainerRef.current, {
      scrollTo: { y: maxScroll, autoKill: true },
      duration: remainingDuration,
      ease: "none",
      onComplete: () => {
        // No restart animation once bottom is reached
      },
    });
  }, [projectsLength, scrollContainerRef, autoScrollTweenRef, scrollSpeed, isPaused]);

  const handleScroll = useCallback(() => {
    if (autoScrollTweenRef.current) autoScrollTweenRef.current.kill();
    if (restartTimerRef.current) restartTimerRef.current.kill();

    if (!isPaused) {
      restartTimerRef.current = gsap.delayedCall(0, () => {
        if (!scrollContainerRef.current) return;
        const currentScroll = scrollContainerRef.current.scrollTop;
        const maxScroll =
          scrollContainerRef.current.scrollHeight -
          scrollContainerRef.current.clientHeight;
        if (currentScroll < maxScroll) {
          startAutoScroll();
        }
      });
    }
  }, [
    startAutoScroll,
    scrollContainerRef,
    autoScrollTweenRef,
    restartTimerRef,
    isPaused,
  ]);

  const pauseScroll = useCallback(() => {
    setIsPaused(true);
    if (autoScrollTweenRef.current) autoScrollTweenRef.current.pause();
  }, [autoScrollTweenRef]);

  const resumeScroll = useCallback(() => {
    setIsPaused(false);
    if (autoScrollTweenRef.current) {
      autoScrollTweenRef.current.resume();
    } else {
      startAutoScroll();
    }
  }, [autoScrollTweenRef, startAutoScroll]);

  useEffect(() => {
    const setupObserver = () => {
      if (!scrollContainerRef.current) return;
      Observer.create({
        target: scrollContainerRef.current,
        type: "wheel,touch",
        onUp: handleScroll,
        onDown: handleScroll,
      });
    };

    startAutoScroll();
    setupObserver();

    return () => {
      if (autoScrollTweenRef.current) autoScrollTweenRef.current.kill();
      if (restartTimerRef.current) restartTimerRef.current.kill();
    };
  }, [
    startAutoScroll,
    scrollContainerRef,
    handleScroll,
    autoScrollTweenRef,
    restartTimerRef,
  ]);

  return { startAutoScroll, pauseScroll, resumeScroll, isPaused };
};

export default useAutoScroll;