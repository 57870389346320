"use client";
export default function ShowFooterBtn() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="20" fill="white" />
      <circle cx="11" cy="20" r="2.5" fill="black" />
      <circle cx="20" cy="20" r="2.5" fill="black" />
      <circle cx="29" cy="20" r="2.5" fill="black" />
    </svg>
  );
}
