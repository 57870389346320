"use client";
import { Dispatch, RefObject, useEffect } from "react";

export default function useObserveFooter(
  scrollContainerRef: RefObject<HTMLDivElement>,
  footerRef: RefObject<HTMLDivElement>,
  setIsFooterVisible: Dispatch<React.SetStateAction<boolean>>
) {
  useEffect(() => {
    const options = {
      root: scrollContainerRef.current,
      rootMargin: "0px",
      threshold: 0,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        setIsFooterVisible(entry.isIntersecting);
      });
    }, options);

    if (footerRef.current) {
      observer.observe(footerRef.current);
    }

    return () => {
      if (footerRef.current) {
        observer.unobserve(footerRef.current);
      }
    };
  }, []);
}
