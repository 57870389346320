import { useCallback, RefObject } from "react";
import gsap from "gsap";

interface UseScrollToProps {
  scrollContainerRef: RefObject<HTMLDivElement>;
  footerRef: RefObject<HTMLDivElement>;
  autoScrollTweenRef: RefObject<gsap.core.Tween>;
  restartTimerRef: RefObject<gsap.core.Tween>;
  startAutoScroll: () => void;
  totalImages: number;
}

const useScrollTo = ({
  scrollContainerRef,
  footerRef,
  autoScrollTweenRef,
  restartTimerRef,
  startAutoScroll,
  totalImages,
}: UseScrollToProps) => {
  const cleanupTweens = useCallback(() => {
    if (autoScrollTweenRef.current) autoScrollTweenRef.current.kill();
    if (restartTimerRef.current) restartTimerRef.current.kill();
  }, [autoScrollTweenRef, restartTimerRef]);

  const calculateDuration = (start: number, end: number) => {
    const scrollDistance = Math.abs(end - start);
    const scrollPerImage =
      scrollContainerRef.current?.scrollHeight! / totalImages;
    const imagesToScroll = scrollDistance / scrollPerImage;
    return Math.min(Math.max(imagesToScroll, 3), 20);
  };

  const scrollTo = useCallback(
    (
      target: number | HTMLElement,
      onComplete?: () => void,
      durationMs?: number
    ) => {
      cleanupTweens();
      const container = scrollContainerRef.current;
      if (!container) return;

      const start = container.scrollTop;
      const end = typeof target === "number" ? target : target.offsetTop;
      const duration = durationMs || calculateDuration(start, end);

      gsap.to(container, {
        scrollTo: { y: target, autoKill: true },
        duration,
        onComplete,
      });
    },
    [scrollContainerRef, cleanupTweens, totalImages]
  );

  const scrollFooter = () => {
    if (footerRef.current) {
      scrollTo(footerRef.current, undefined, 0.01);
    }
  };

  const scrollTop = () => {
    if (scrollContainerRef.current) {
      scrollTo(
        0,
        () => {
          startAutoScroll();
        },
        0.01
      );
    }
  };

  return { scrollFooter, scrollTop };
};

export default useScrollTo;
